@import '../scss/variable';

/*=====================================================*/
/* General - CSS */
/*=====================================================*/
.custom-sticky {
  top: 125px;
  z-index: 1;
}
.color-inherit,
.color-inherit:hover {
  color: inherit;
}
.modal-backdrop {
  --bs-backdrop-opacity: 0.8;
}

/*=====================================================*/
/* Border Radius */
/*=====================================================*/
.border-radius {
  border-radius: $radius-3;
}
.border-radius-5 {
  border-radius: $radius-5;
}
.border-radius-7 {
  border-radius: $radius-7;
}
.border-radius-12 {
  border-radius: $radius-12;
}
.border-radius-30 {
  border-radius: $radius-30;
}
.border-radius-50 {
  border-radius: $radius-50;
}
.border-radius-80 {
  border-radius: $radius-80;
}
.border {
  border-color: $border-color !important;
}

/*=====================================================*/
/* Text Color */
/*=====================================================*/
.text-primary {
  color: $primary-color !important;
}
.text-secondary {
  color: $secondary-color !important;
}
.text-dark {
  color: $dark-color !important;
}
.text-light {
  color: $light-color !important;
}
.text-white {
  color: $white-color !important;
}

/*=====================================================*/
/* Background Color */
/*=====================================================*/
.bg-primary {
  background-color: $primary-color !important;
}
.bg-secondary {
  background-color: $secondary-color !important;
}
.bg-dark {
  background-color: $dark-color !important;
}
.bg-gray {
  background-color: $gray-color-2 !important;
}
.bg-light {
  background-color: $light-color !important;
}
.bg-white {
  background-color: $white-color !important;
}

/*=====================================================*/
/* Background Gradient Color */
/*=====================================================*/

.bg-primary-gradient {
  background: $bg-primary-gradient !important;
}

.bg-dark-gradient {
  background: $bg-dark-gradient !important;
}

/* fill */
.fill-primary {
  fill: $primary-color;
  fill-rule: evenodd;
}
.fill-dark {
  fill: $dark-color;
  fill-rule: evenodd;
}
.fill-gray {
  fill: $gray-color-2;
  fill-rule: evenodd;
}
.fill-light {
  fill: $light-color;
  fill-rule: evenodd;
}
.fill-white {
  fill: $white-color;
  fill-rule: evenodd;
}
.fill-gradient {
  fill: url(#Gradient);
  fill-rule: evenodd;
}
.fill-gradient-02 {
  fill: url(#Gradient-02);
  fill-rule: evenodd;
}
.fill-gradient-1 {
  stop-color: $primary-color;
}
.fill-gradient-2 {
  stop-color: $primary-color-2;
}

/*=====================================================*/
/* Background Overlay Color */
/*=====================================================*/
.bg-overlay-primary,
.bg-overlay-secondary,
.bg-overlay-black,
.bg-overlay-white {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.bg-overlay-primary:before {
  background-color: $primary-color;
}
.bg-overlay-secondary:before {
  background-color: $secondary-color;
}
.bg-overlay-black:before {
  background-color: $dark-color;
}
.bg-overlay-white:before {
  background-color: $white-color;
}

.bg-overlay-primary:before,
.bg-overlay-secondary:before,
.bg-overlay-black:before,
.bg-overlay-white:before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.9;
}
.overlay-10:before {
  opacity: 0.1;
}
.overlay-20:before {
  opacity: 0.2;
}
.overlay-30:before {
  opacity: 0.3;
}
.overlay-40:before {
  opacity: 0.4;
}
.overlay-50:before {
  opacity: 0.5;
}
.overlay-60:before {
  opacity: 0.6;
}
.overlay-70:before {
  opacity: 0.7;
}
.overlay-80:before {
  opacity: 0.8;
}
.overlay-90:before {
  opacity: 0.9;
}

/* avatar */
.avatar {
  width: 50px;
  height: 50px;
  display: inline-block;
}
.avatar.avatar-sm {
  width: 20px;
  height: 20px;
}

.avatar.avatar-md {
  width: 80px;
  height: 80px;
}

.avatar.avatar-lg {
  width: 145px;
  height: 145px;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Social Bg Color */

.social-bg-hover {
  position: relative;
  color: $white-color;
}

.social-bg-hover:before {
  content: '';
  color: $white-color;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.social-bg-hover:hover {
  color: $white-color;
}

.social-bg-hover:hover:before {
  background-color: rgba($black-color, 0.1);
}

.social-bg-hover span {
  position: relative;
  font-size: 14px;
  font-weight: 700;
}
.social-bg-hover span i {
  padding-right: 10px;
}

.facebook-bg {
  background-color: #445c8e !important;
}

.twitter-bg {
  background-color: #43afe9 !important;
}

.google-bg {
  background-color: #dc0510 !important;
}

.instagram-bg {
  background-color: #dd2a7b !important;
}

.linkedin-bg {
  background-color: #007eb3 !important;
}

/* error */
.error-text {
  font-size: 268px;
  line-height: 266px;
  font-weight: 800;
  font-family: $title-font;
  color: $dark-color;
}

/*=====================================================*/
/* Swiper Slider */
/*=====================================================*/
.swiper .swiper-pagination {
  position: relative;
  margin-top: 40px;
  text-align: left;
}
.swiper .swiper-pagination .swiper-pagination-bullet {
  background: $gray-color-4;
  display: inline-block;
  width: 10px;
  height: 4px;
  border-radius: $radius-3;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  opacity: 1;
}
.swiper .swiper-pagination .swiper-pagination-bullet:hover {
  width: 30px;
  background: $primary-color;
}

.swiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: $primary-color;
  width: 30px;
}

/* Swiper Custom Arrow */

.swiper .swiper-button-prev,
.swiper .swiper-button-next {
  position: absolute;
  top: inherit;
  bottom: 50%;
  width: 18px;
  height: 2px;
  display: inline-block;
  background: $primary-color;
  transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s;
}
.swiper .swiper-button-prev {
  margin-right: 15px;
}
.swiper .swiper-button-next {
  margin-left: 15px;
}

.swiper .swiper-button-next:after,
.swiper .swiper-button-prev:after {
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  border-radius: 2px;
  background: inherit;
}
.swiper .swiper-button-next:before,
.swiper .swiper-button-prev:before {
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  border-radius: 2px;
  background: inherit;
}

.swiper .swiper-button-prev:before {
  transform: rotate(-45deg);
  top: -4px;
  left: 0px;
  width: 10px;
}
.swiper .swiper-button-prev:after {
  transform: rotate(45deg);
  width: 10px;
  bottom: -4px;
  left: 0px;
}
.swiper .swiper-button-next:before {
  transform: rotate(45deg);
  top: -4px;
  right: 0px;
  width: 10px;
}
.swiper .swiper-button-next:after {
  transform: rotate(-45deg);
  width: 10px;
  bottom: -4px;
  right: 0px;
}

.swiper .swiper-button-prev:hover,
.swiper .swiper-button-next:hover {
  opacity: 1;
  width: 50px;
}

/* Swiper Arrow Light */
.swiper.swiper-slider-light .swiper-button-prev:after,
.swiper.swiper-slider-light .swiper-button-next:after {
  color: $white-color;
}

/*=====================================================*/
/* List Item */
/*=====================================================*/
.list-item {
  display: grid;
  grid-gap: 5px 15px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.list-item li {
  font-size: 15px;
  line-height: 28px;
  color: $dark-color;
}

/*=====================================================*/
/* Section Half-BG */
/*=====================================================*/
.bg-primary-half,
.bg-secondary-half,
.bg-light-half {
  position: relative;
}
.bg-primary-half:before,
.bg-secondary-half:before,
.bg-light-half:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 470px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.bg-primary-half:before {
  background: $primary-color;
}
.bg-secondary-half:before {
  background: $secondary-color;
}
.bg-light-half:before {
  background: $light-color;
}

[data-bg-height='200']:before {
  height: 200px;
}
[data-bg-height='250']:before {
  height: 250px;
}
[data-bg-height='300']:before {
  height: 300px;
}
[data-bg-height='350']:before {
  height: 350px;
}
[data-bg-height='400']:before {
  height: 400px;
}
[data-bg-height='450']:before {
  height: 450px;
}
[data-bg-height='500']:before {
  height: 500px;
}
[data-bg-height='550']:before {
  height: 550px;
}
[data-bg-height='600']:before {
  height: 600px;
}

/*=====================================================*/
/* Section Top Half-BG */
/*=====================================================*/
/* Top Half Background */

.bg-light-half-lg,
.bg-light-half-md,
.bg-light-half-sm {
  position: relative;
}

.bg-light-half-lg:before,
.bg-light-half-md:before,
.bg-light-half-sm:before {
  content: '';
  background: $light-color;
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-light-half-lg:before {
  height: 470px;
}

.bg-light-half-md:before {
  height: 370px;
}

.bg-light-half-sm:before {
  height: 250px;
}

/*=====================================================*/
/* Main Banner */
/*=====================================================*/
.banner-slider {
  height: 900px;
}
.banner-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.banner-content {
  text-align: center;
  color: $white-color;
}
.banner-item .title {
  font-family: $title-font;
  color: $white-color;
  font-size: 112px;
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 25px;
}
.banner-item h6 {
  color: $white-color;
}

.banner-content .text-stroke-white {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  display: block;
}
.banner-content .font-large {
  font-size: 86px;
  line-height: 100px;
}

.banner-section .banner-content .btn-secondary {
  margin-top: 15px;
}

/* Banner Slider Arrow */
.banner-section .swiper .swiper-button-prev {
  left: 70px;
}
.banner-section .swiper .swiper-button-next {
  right: 70px;
}
.banner-section .swiper .swiper-button-prev,
.banner-section .swiper .swiper-button-next {
  display: flex;
  text-align: center;
  opacity: 1;
  background: $primary-color;
  border: none;
  outline: none;
  border-radius: 50%;
}
.banner-section .swiper .swiper-button-prev:after,
.banner-section .swiper .swiper-button-next:after {
  font-size: 16px;
}
.banner-section .swiper .swiper-button-prev:hover,
.banner-section .swiper .swiper-button-next:hover {
  opacity: 1;
}

/*-----------------------*/
/* Overlay Home Banner */
/*-----------------------*/
.overlay-home-banner {
  height: 750px;
  padding-top: 150px;
}
.banner-logo {
  display: inline-block;
  padding: 10px 20px;
  background: $primary-color;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 240px;
  flex: 0 0 240px;
  color: $white-color;
  font-size: 70px;
  line-height: 60px;
  position: relative;
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: 20px;
  text-align: center;
  border-radius: $radius-7;
}
.banner-logo:before {
  position: absolute;
  content: '';
  width: 30px;
  height: 21px;
  left: -30px;
  border-top: 20px solid rgba(239, 49, 57, 0);
  border-right: 20px solid $primary-color;
  top: 15px;
}
.banner-list {
  padding: 0;
  margin: 30px 0 50px;
  list-style-type: none;
  text-align: center;
}
.banner-list li {
  display: inline-block;
}
.banner-list li a {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  border-right: 2px solid #ffffff;
  padding: 0 15px;
}

/*=====================================================*/
/* Home Page 1 */
/*=====================================================*/

.videopopup-btn .video-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.assistance-form {
  margin-top: -110px;
}

.videoshape-01 {
  width: 110px;
  height: 110px;
  position: absolute;
  bottom: 54px;
  left: 45px;
  z-index: 2;
}
.videoshape-02 {
  width: 264px;
  height: 364px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -120px;
  z-index: -1;
}

.countershape-01 {
  width: 95px;
  height: 95px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -48px;
  margin-top: -48px;
  z-index: -1;
  opacity: 0.1;
}

.countershape-02 {
  width: 225px;
  height: 225px;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: -48px;
  margin-left: -144px;
  z-index: -1;
  opacity: 0.1;
}

.teamshape {
  width: 222px;
  height: 382px;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: -144px;
}

/*=====================================================*/
/* Home Page 2 */
/*=====================================================*/

.bg-testimonial {
  margin-top: -50px;
  position: relative;
}

.home-02 .contact-info-section {
  margin-top: -50px;
}

/*=====================================================*/
/* Home Page 3 */
/*=====================================================*/
.banner-03 {
  position: relative;
  background-color: $dark-color;
  height: 300px;
  overflow-x: hidden;
  z-index: 2;
}

.banner-03 .page-header-shape {
  position: absolute;
  width: 100%;
  height: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  margin-top: -112px;
}

.banner-03 .page-header-shape01 {
  position: absolute;
  width: 130px;
  left: -48px;
  top: 50%;
  transform: translateY(-50%);
}
.banner-03 .page-header-shape02 {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 410px;
  margin-right: -224px;
  margin-top: -112px;
}

.banner-03 .section-title .title {
  font-size: 96px;
  line-height: 1.2;
  font-weight: 800;
  color: $white-color;
}

.banner-03 .section-title p {
  font-size: 24px;
  color: $white-color;
  line-height: 1.2;
  font-family: $title-font;
}
.banner-social {
  position: absolute;
  bottom: 60px;
}
.banner-social .widget .social-list {
  justify-content: flex-end;
  gap: 5px 20px;
}
.banner-social .widget .social-list li a {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  display: inline-block;
}

.banner-social .widget .social-list li a:hover {
  background: rgba(255, 255, 255, 1);
}

.blogshape {
  width: 222px;
  height: 382px;
  position: absolute;
  left: 0;
  top: -192px;
  margin-left: -144px;
}

/*=====================================================*/
/* Get Touch */
/*=====================================================*/

.get-touch {
  background: $light-color;
  padding: 30px;
  text-align: center;
  position: relative;
  height: 350px;
  overflow: hidden;
}
.get-touch .phone {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 700;
  color: $title-color;
}
.get-touch .get-touch-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
  margin-bottom: 24px;
}
.get-touch .gettouchshape {
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: 0;
  height: auto;
}

/*=====================================================*/
/* About Us Page */
/*=====================================================*/

.about-services .feature-info {
  padding: 70px 80px;
}

.about-services .service-wrapper .feature-info:first-of-type {
  background-color: $dark-color;
}

.about-services
  .service-wrapper
  .feature-info:first-of-type
  .feature-info-icon
  .feature-info-title,
.about-services
  .service-wrapper
  .feature-info:first-of-type
  .feature-info-content
  p {
  color: $white-color;
}

.about-services .service-wrapper .feature-info:nth-of-type(2) {
  background-color: $primary-color;
}
.about-services
  .service-wrapper
  .feature-info:nth-of-type(2)
  .feature-info-icon
  i {
  color: $white-color !important;
  -webkit-text-fill-color: $white-color;
  background: none;
}
.about-services
  .service-wrapper
  .feature-info:nth-of-type(2)
  .feature-info-icon
  .feature-info-title,
.about-services
  .service-wrapper
  .feature-info:nth-of-type(2)
  .feature-info-content
  p {
  color: $white-color;
}

.about-services
  .service-wrapper
  .feature-info:nth-of-type(2)
  .feature-info-content
  .icon-btn {
  color: $white-color;
}

.about-services
  .service-wrapper
  .feature-info:nth-of-type(2)
  .feature-info-content
  .icon-btn.btn-arrow
  span {
  background: $white-color;
}

.about-services .service-wrapper .feature-info:nth-of-type(3) {
  background-color: $light-color;
}

.about-services
  .service-wrapper
  .feature-info:nth-of-type(3)
  .feature-info-content
  .icon-btn {
  color: $primary-color;
}

.about-services
  .service-wrapper
  .feature-info:nth-of-type(3)
  .feature-info-content
  .icon-btn.btn-arrow
  span {
  background: $primary-color;
}

/*=====================================================*/
/* About Me Page */
/*=====================================================*/
.sticky-img.left-split {
  position: fixed;
  top: 90px;
  width: 50%;
}
.abou-me-contactinfo.widget .info-list li {
  font-size: 18px;
  margin-bottom: 15px;
}

/*=====================================================*/
/* Service Page */
/*=====================================================*/

img.servicebgshape {
  width: 110px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
  margin-bottom: -45px;
  margin-left: -45px;
}

.service-step-wrapper .feature-step {
  padding: 50px;
}

.service-step-wrapper .feature-step .step-content p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.service-step-wrapper .feature-step:first-of-type {
  background-color: $primary-color;
}
.service-step-wrapper
  .feature-step:first-of-type
  .feature-step-number
  .step-number {
  color: $white-color !important;
  -webkit-text-fill-color: $white-color;
  background: none;
}
.service-step-wrapper
  .feature-step:first-of-type
  .feature-step-number
  .step-title {
  color: $white-color;
}

.service-step-wrapper .feature-step:first-of-type .step-content p {
  color: $white-color;
}
.service-step-wrapper .feature-step:nth-of-type(3) {
  background-color: $light-color;
}
.service-step-wrapper .feature-step:nth-of-type(4) {
  background-color: $dark-color;
}

.service-step-wrapper
  .feature-step:nth-of-type(4)
  .feature-step-number
  .step-title,
.service-step-wrapper .feature-step:nth-of-type(4) .step-content p {
  color: $white-color;
}

/*=====================================================*/
/* Service Detail Page */
/*=====================================================*/
.keyfeaturesshape {
  width: 177px;
  height: 188px;
  position: absolute;
  right: 20px;
  bottom: -65px;
  z-index: -1;
}

.contact-info-section {
  margin-top: -120px;
}

.contactinfoshape {
  position: absolute;
  height: 110px;
  width: 110px;
  bottom: 0;
  right: 0;
  margin-bottom: -47px;
  margin-right: -52px;
}

/*=====================================================*/
/* Contact Page */
/*=====================================================*/

.map-section iframe {
  height: 500px;
}

/*==========================================================*/
/* Team SIngle Page */
/*==========================================================*/
.team-single-page .team-info {
  padding: 0;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  list-style-type: none;
}
.team-single-page .team-info li {
  width: 100%;
}
.team-single-page .team-info li label {
  min-width: 80px;
}
.team-single-page .team-info li .title {
  color: $title-color;
  margin-bottom: 0;
  display: flex;
  line-height: 1.5;
}

.team-single-page .personal-info {
  background: #f5f7fa;
  padding: 45px;
  border-radius: 12px;
}
.team-single-page .personal-info .title-area {
  display: flex;
  align-items: baseline;
}
.team-single-page .personal-info .title {
  margin-bottom: 0px;
  margin-right: 15px;
}
.team-single-page .personal-info .position {
  color: $primary-color;
}
.team-single-page .personal-info .team-social {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
  list-style-type: none;
}
.team-single-page .personal-info .team-social li a {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $white-color;
  background-color: $primary-color;
  font-size: 15px;
  overflow: hidden;
  text-decoration: none;
  border-radius: 100%;
  border: 2px solid $primary-color;
  transition: $transition-3;
}
.team-single-page .personal-info .team-social li a:hover {
  background-color: $dark-color;
  border-color: $dark-color;
  color: $white-color;
}

/*=====================================================*/
/* Signin Page */
/*=====================================================*/
.coming-soon-conten,
.signin-conten {
  padding: 50px 80px;
}

.signin-shape01 {
  position: absolute;
  top: 0;
  right: 0;
  width: 264px;
  height: 364px;
  margin-top: -112px;
  margin-right: -144px;
}

.signin-shape02 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 110px;
  height: 110px;
  margin-bottom: -48px;
  margin-left: -48px;
  z-index: -1;
}

/*=====================================================*/
/* Sign-up Page */
/*=====================================================*/
.sign-up-page .pgs-form .checkbox-control {
  flex-wrap: wrap;
}

/*=====================================================*/
/* Blog Page */
/*=====================================================*/

.actionboxshape {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: -48px;
  margin-bottom: -48px;
}

/*==========================================================*/
/* Responsive CSS */
/*==========================================================*/

@media only screen and (min-width: 767px) {
  .border-md-right {
    border-right: 1px solid $border-color !important;
  }
}

@media only screen and (max-width: 1299px) {
  .contactinfoshape {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  /*----------------------------*/
  /* Signin Page */
  /*----------------------------*/
  .coming-soon-conten,
  .signin-conten {
    padding: 50px;
  }
}

@media only screen and (max-width: 991px) {
  /*----------------------------*/
  /* Home 1 : Main Banner */
  /*----------------------------*/
  .banner-slider {
    height: 600px;
  }
  .banner-content {
    padding: 0 15px;
  }
  .banner-item .title {
    font-size: 66px;
    line-height: 76px;
    margin-bottom: 10px;
  }

  .services-title {
    font-size: 48px;
    margin: 0px 0px 15px 0px;
  }

  .services-img {
    margin-top: 0;
  }

  .services-list-box {
    margin-top: 25px;
  }

  .our-portfolio {
    margin-top: 50px;
  }

  .about-services .feature-info {
    padding: 25px;
  }

  /*----------------------------*/
  /* Home 3 */
  /*----------------------------*/
  .banner-03 {
    height: 700px;
  }
  .banner-03 .page-header-shape01 {
    display: none;
  }
  .banner-03 .section-title .title {
    font-size: 72px;
  }

  .banner-03 .section-title p {
    font-size: 20px;
  }

  /*----------------------------*/
  /* Signin Page */
  /*----------------------------*/
  .coming-soon-conten,
  .signin-conten {
    padding: 25px;
  }
}

@media only screen and (max-width: 767px) {
  /*----------------------------*/
  /* Home 1 : Main Banner */
  /*----------------------------*/
  .banner-slider {
    height: 500px;
  }
  .banner-item .title {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 10px;
  }

  /*----------------------------*/
  /* Home 3 */
  /*----------------------------*/
  .banner-03 {
    height: 400px;
  }
  .banner-03 .page-header-shape {
    width: 80%;
  }
  .banner-03 .page-header-shape02 {
    display: none;
  }
  .banner-03 .section-title .title {
    font-size: 56px;
  }

  .banner-social {
    display: none;
  }

  /* get-touch */
  .get-touch {
    margin-top: 50px;
  }
  .get-touch .gettouchshape {
    bottom: -50px;
  }

  .sticky-img.right-split,
  .sticky-img.left-split {
    position: inherit;
    top: 0;
    width: 90%;
    right: 0;
    left: 0;
    margin: auto;
    margin-bottom: 30px;
  }

  .swiper .swiper-button-prev,
  .swiper .swiper-button-next {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .font-xlll {
    font-size: 30px;
    line-height: 30px;
  }

  .service-step-wrapper .feature-step {
    padding: 30px 20px;
  }

  /*----------------------------*/
  /* Home 3 */
  /*----------------------------*/

  .banner-03 .section-title .title {
    font-size: 40px;
  }
}

@media (max-width: 479px) {
}
