/*
  	Flaticon icon font: Flaticon
  	Creation date: 14/03/2020 06:05
  	*/

@font-face {
  font-family: 'Flaticon';
  src: url('../../../assets/fonts/flaticon/Flaticon.eot');
  src: url('../../../assets/fonts/flaticon/Flaticon.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/flaticon/Flaticon.woff2') format('woff2'),
    url('../../../assets/fonts/flaticon/Flaticon.woff') format('woff'),
    url('../../../assets/fonts/flaticon/Flaticon.ttf') format('truetype'),
    url('../../../assets/fonts/flaticon/Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('../../../assets/fonts/flaticon/Flaticon.svg#Flaticon')
      format('svg');
  }
}

[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-paper-plane:before {
  content: '\f100';
}
.flaticon-design:before {
  content: '\f101';
}
.flaticon-chart:before {
  content: '\f102';
}
.flaticon-audio:before {
  content: '\f103';
}
.flaticon-data:before {
  content: '\f104';
}
.flaticon-group:before {
  content: '\f105';
}
.flaticon-rocket:before {
  content: '\f106';
}
.flaticon-diamond:before {
  content: '\f107';
}
.flaticon-document:before {
  content: '\f108';
}
.flaticon-support:before {
  content: '\f109';
}
.flaticon-phone:before {
  content: '\f10a';
}
.flaticon-world:before {
  content: '\f10b';
}
.flaticon-analytics:before {
  content: '\f10c';
}
.flaticon-target:before {
  content: '\f10d';
}
.flaticon-idea:before {
  content: '\f10e';
}
.flaticon-idea-1:before {
  content: '\f10f';
}
.flaticon-return:before {
  content: '\f110';
}
.flaticon-trajectory:before {
  content: '\f111';
}
.flaticon-eye:before {
  content: '\f112';
}
.flaticon-icon-751463:before {
  content: '\f113';
}
.flaticon-icon-253674:before {
  content: '\f114';
}
.flaticon-icon-149294:before {
  content: '\f115';
}
.flaticon-pin:before {
  content: '\f116';
}
.flaticon-like:before {
  content: '\f117';
}
.flaticon-profit:before {
  content: '\f118';
}
.flaticon-console:before {
  content: '\f119';
}
.flaticon-wifi:before {
  content: '\f11a';
}
.flaticon-smartphone:before {
  content: '\f11b';
}
.flaticon-podcast:before {
  content: '\f11c';
}
.flaticon-cctv:before {
  content: '\f11d';
}
.flaticon-icon-1243560:before {
  content: '\f11e';
}
.flaticon-piggy-bank:before {
  content: '\f11f';
}
.flaticon-icon-626443:before {
  content: '\f120';
}
.flaticon-icon-160200:before {
  content: '\f121';
}
.flaticon-thunder:before {
  content: '\f122';
}
.flaticon-car:before {
  content: '\f123';
}
.flaticon-trophy:before {
  content: '\f124';
}
.flaticon-strong:before {
  content: '\f125';
}
.flaticon-envelope:before {
  content: '\f126';
}
.flaticon-radio:before {
  content: '\f127';
}
.flaticon-trolley:before {
  content: '\f128';
}
.flaticon-icon-149196:before {
  content: '\f129';
}
.flaticon-heart:before {
  content: '\f12a';
}
.flaticon-icon-149103:before {
  content: '\f12b';
}
.flaticon-mouse:before {
  content: '\f12c';
}
.flaticon-watch:before {
  content: '\f12d';
}
.flaticon-power:before {
  content: '\f12e';
}
.flaticon-lamp:before {
  content: '\f12f';
}
.flaticon-router:before {
  content: '\f130';
}
.flaticon-invention:before {
  content: '\f131';
}
.flaticon-cloud:before {
  content: '\f132';
}
.flaticon-film:before {
  content: '\f133';
}
.flaticon-brain:before {
  content: '\f134';
}
.flaticon-air-conditioner:before {
  content: '\f135';
}
.flaticon-monitor:before {
  content: '\f136';
}
.flaticon-pendrive:before {
  content: '\f137';
}
.flaticon-solar-energy:before {
  content: '\f138';
}
.flaticon-server:before {
  content: '\f139';
}
.flaticon-help:before {
  content: '\f13a';
}
.flaticon-hard-drive:before {
  content: '\f13b';
}
.flaticon-seo-and-web:before {
  content: '\f13c';
}
.flaticon-tools-and-utensils:before {
  content: '\f13d';
}
.flaticon-electrician:before {
  content: '\f13e';
}
.flaticon-add-user:before {
  content: '\f13f';
}
.flaticon-monitor-1:before {
  content: '\f140';
}
.flaticon-wechat:before {
  content: '\f141';
}
.flaticon-download:before {
  content: '\f142';
}
.flaticon-share:before {
  content: '\f143';
}
.flaticon-left-chevron:before {
  content: '\f144';
}
.flaticon-right-chevron:before {
  content: '\f145';
}
.flaticon-arrow:before {
  content: '\f146';
}
.flaticon-left:before {
  content: '\f147';
}
.flaticon-twitter:before {
  content: '\f148';
}
.flaticon-left-1:before {
  content: '\f149';
}
.flaticon-messenger:before {
  content: '\f14a';
}
.flaticon-drive:before {
  content: '\f14b';
}
.flaticon-linkedin:before {
  content: '\f14c';
}
.flaticon-youtube:before {
  content: '\f14d';
}
.flaticon-android:before {
  content: '\f14e';
}
.flaticon-right:before {
  content: '\f14f';
}
.flaticon-medal:before {
  content: '\f150';
}
.flaticon-firefox:before {
  content: '\f151';
}
.flaticon-emoji:before {
  content: '\f152';
}
.flaticon-classified:before {
  content: '\f153';
}
.flaticon-author:before {
  content: '\f154';
}
.flaticon-html:before {
  content: '\f155';
}
.flaticon-icon-546448:before {
  content: '\f156';
}
.flaticon-mobile-phone:before {
  content: '\f157';
}
.flaticon-laptop:before {
  content: '\f158';
}
.flaticon-quote:before {
  content: '\f159';
}
.flaticon-quote-1:before {
  content: '\f15a';
}
.flaticon-download-1:before {
  content: '\f15b';
}
.flaticon-pinterest:before {
  content: '\f15c';
}
.flaticon-food:before {
  content: '\f15d';
}
.flaticon-layers:before {
  content: '\f15e';
}
.flaticon-gift:before {
  content: '\f15f';
}
.flaticon-chat:before {
  content: '\f160';
}
