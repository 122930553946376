/*--------------------------------------------*/
/* Typography Variable */
/*--------------------------------------------*/
$body-font: 'Heebo', sans-serif;
$title-font: 'Montserrat', sans-serif;

/*--------------------------------------------*/
/* Color Variable */
/*--------------------------------------------*/
$body-color: #626262; // body-text
$primary-color: #ff6600;//#fd4632; // Primary color
$primary-color-2: #c54f00; //#fa3a72; // Primary 2 color
$secondary-color: #0A1299; //#592036;
$white-color: #ffffff;
$light-color: #fcfcfc; // bg light
$gray-color-2: #f4f4f5;
$gray-color-3: #cdcdcd;
$gray-color-4: #c1c1c1;
$gray-color-5: #b7b7b7;
$gray-color-6: #9b9b9b;
$gray-color-7: #999999;
$gray-color-8: #323232;
$title-color: #0A1299;//#323232; // Hedding & text color color
$main-navbar-text-color: #ffffff;
$dark-color: #000000; //#0A1299; // dark bg color
$black-color: #000000;

$border-color: #eeeeee;

/*--------------------------------------------*/
/* Other Variable */
/*--------------------------------------------*/

$box-shadow-sm: 0px 5px 16px 0px rgba($gray-color-7, 0.15);
$box-shadow: 0px 8px 27px 0px rgba($gray-color-7, 0.15);
$boxshadow-lg: 0 1rem 3rem rgba($gray-color-7, 0.175);

// $bg-primary-gradient: linear-gradient(
//   to right,
//   $primary-color 30%,
//   $primary-color-2 100%
// );
$bg-primary-gradient: #000000;

$bg-dark-gradient: linear-gradient(to right, #161427 10%, #592036 100%);

$radius-3: 3px;
$radius-5: 5px;
$radius-7: 7px;
$radius-12: 12px;
$radius-30: 30px;
$radius-50: 50px;
$radius-80: 80px;

$transition: all 0.25s ease-in-out;
$transition-2: all 0.2s ease-in-out;
$transition-3: all 0.3s ease-in-out;
$transition-4: all 0.4s ease-in-out;
$transition-5: all 0.5s ease-in-out;
